import axios from 'axios';
import {
    getDate,
    getFormattedLocalTime,
    getLocalTimezone,
} from '@/core/helpers/utils';

// Api 2 is absolutely same as "main api", just has additional layer or reply parsing
const axios99 = () => {
    const userToken = localStorage.getItem('authToken');

    const guestToken = localStorage.getItem('guestToken');

    const jwt = userToken || guestToken;

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Local-Time': getFormattedLocalTime(),
        'X-Timezone': getLocalTimezone(),
        'X-Date': getDate(),
    };

    if (jwt) {
        headers.Authorization = `Bearer ${jwt}`;
    }

    return axios.create({
        baseURL: import.meta.env.VITE_API_URL,
        withCredentials: false,
        headers,
    });
};

const proxy = {
    async request(method, url, opts, returnError = false) {
        let response = null;
        try {
            response = await axios99().request({
                ...(opts || {}),
                url,
                method,
            });
        } catch (err) {
            console.error('Api2::request error', method, url, err);
            response = null;
        }
        if (!response || !response.data) return null;
        if (!response.data.success) {
            console.error(
                'Api2::response not success',
                method,
                url,
                response.data,
            );
            if (returnError) return response.data;
            return null;
        }
        return response.data.data;
    },
    get(url, opts, returnError) {
        return this.request('get', url, opts, returnError);
    },
    post(url, opts, returnError) {
        return this.request('post', url, opts, returnError);
    },
    delete(url, opts, returnError) {
        return this.request('delete', url, opts, returnError);
    },
};

export default proxy;
