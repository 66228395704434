import Api from '@/core/services/Api2';
import { toCache, fromCache, delCache } from '@/core/helpers/utils.cache';
import { roundUpToNearest10 } from '@/core/helpers/utils';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import { omit } from 'lodash';
import moment from 'moment';
import CONSTANTS from '@/core/helpers/constants';

export default {
    namespaced: true,
    state: {
        playlist: [],
        playListCommitedAt: null,
        sessionId: null,
        code: null,
        math: null,
        questions: [],
        stats: null,
        timerSync: null,
        timerHandler: null,
        playAgain: false,
    },
    getters: {
        game: (state) => {
            if (!state.playlist.length) {
                return null;
            }

            if (!state.code) {
                return null;
            }

            return state.playlist.find((g) => g.code === state.code) || null;
        },
        session: (state) => {
            if (!state.playlist.length) {
                return null;
            }

            if (!state.code) {
                return null;
            }

            const game = state.playlist.find((g) => g.code === state.code);

            if (!game) {
                return null;
            }

            const player = game.players[0];

            if (!player) {
                return null;
            }

            if (!player.sessionId || !player.playerSessions.length) {
                return null;
            }

            return player.playerSessions.find(
                (s) => s.sessionId === player.sessionId,
            );
        },
        score: (state) => {
            if (!state.stats) {
                return 0;
            }

            return ((state.stats || {}).answers || []).reduce((acc, answer) => {
                const next = answer.correct ? acc + 1 : acc - 1;

                if (next < 0) {
                    return 0;
                }

                return next;
            }, 0);
        },
        isStarted: (state) => !!(state.stats && state.stats.started),
        isEnded: (state) => !!(state.stats && state.stats.playedTime),
        isFinished: (state, getters) => {
            if (!getters.isStarted) {
                return false;
            }

            if (getters.isTimeGame) {
                const started = moment(state.stats.started);

                const now = moment();

                const len = getters.game.options.duration * 60;

                return now.unix() - started.unix() > len;
            }
            if (getters.isCountGame) {
                const answers =
                    state.stats && Array.isArray(state.stats.answers)
                        ? state.stats.answers.length
                        : 0;

                return answers >= getters.game.options.questionCount;
            }

            return getters.isEnded;
        },
        isCountdown: (state, getters) => {
            if (!getters.isTimeGame) {
                return false;
            }

            if (!getters.isStarted) {
                return false;
            }

            const ts = moment(state.stats.started).clone();

            const len = getters.game.options.duration * 60;

            const duration = ts.clone().add(len * -1, 's');

            const dl = duration.toDate().getTime();

            const now = new Date().getTime();

            return dl > now;
        },
        isTeacherCreated: (state, getters) =>
            getters.game?.creatorRole === 'teacher',
        isTimeGame: (state, getters) =>
            getters.game?.options?.duration > 0 &&
            getters.game?.gameVariant === 'time',
        isCountGame: (state, getters) =>
            getters.game?.options?.questionCount > 0 &&
            getters.game?.gameVariant === 'questions',
        timeGameDuration: (state, getters) => {
            if (!getters.isTimeGame) {
                return 0;
            }

            return getters.game.options.duration * 60;
        },
        timeGameEndTime: (state, getters) => {
            if (!getters.isTimeGame || !state.stats.started) {
                return null;
            }

            const plus = state.playAgain ? 1 : 4;

            const add = getters.game.options.duration * 60 + plus;

            const started = moment(state.stats.started).clone();

            return started.add(add, 's').format();
        },
    },
    mutations: {
        playlist: (state, next) => {
            state.playlist = next || null;

            state.playListCommitedAt = moment();
        },
        removeFromPlaylist: (state, next) => {
            const idx = state.playlist.findIndex((p) => p._id === next);
            console.log('removeFromPlaylist', idx);
            if (idx === -1) return;
            state.playlist.splice(idx, 1);
        },
        code: (state, next) => {
            state.code = next || null;
        },
        timerSync: (state, next) => {
            state.timerSync = next;
        },
        playAgain: (state, next) => {
            state.playAgain = next;
        },
        sessionId: (state, next) => {
            state.sessionId = next;
        },
        stats: (state, next) => {
            state.stats = next || {
                playedTime: 0,
                started: null,
                correctAnswers: 0,
                wrongAnswers: 0,
                answers: [],
            };
        },
        updatePlayer: (state, next) => {
            if (!state.playlist.length) return;

            if (!state.code) return;

            const game = state.playlist.findIndex((g) => g.code === state.code);

            if (game === -1) return;

            if (!state.playlist[game].players.length) return;

            state.playlist[game].players[0] = next;
        },
        ready: (state, { math, questions, stats }) => {
            state.math = math;

            state.questions = questions;

            state.stats = stats || {
                playedTime: 0,
                started: null,
                correctAnswers: 0,
                wrongAnswers: 0,
                answers: [],
            };

            console.debug('store::game/spg::ready', state.code);
        },
        start: (state, { started, handler }) => {
            if (!state.stats || !!state.stats.started) {
                return;
            }

            console.debug('store::game/spg::started', state.code);

            state.stats.started = started;

            if (state.timerHandler) {
                clearInterval(state.timerHandler);
            }

            state.timerHandler = handler;
        },
        answer: (state, next) => {
            const started = state.stats.started;

            state.stats = { ...next, started };
        },
        finish: (state, game) => {
            console.debug('store::game/sp::finish', state.code, game);

            if (state.timerHandler) {
                clearInterval(state.timerHandler);

                state.timerHandler = null;
            }

            state.timerSync = null;

            if (!game) return; // should not happen

            state.playlist = state.playlist.map((gm) =>
                gm._id.toString() === game._id ? game : gm,
            );
        },
        reset: (state, sessionDataOnly = false) => {
            state.sessionId = null;
            state.code = null;

            if (!sessionDataOnly) {
                state.playlist = [];
                state.playListCommitedAt = null;
            }

            state.math = null;
            state.stats = null;
            state.questions = [];
            state.timerSync = null;

            if (state.timerHandler) {
                clearInterval(state.timerHandler);
            }

            state.timerHandler = null;
            state.playAgain = false;
        },
    },
    actions: {
        init: async () => {
            console.debug('store::game/spg::init');
        },
        reset: async (store, sessionDataOnly = false) => {
            console.debug('store::game/spg reset');

            await Promise.all([
                store.commit('reset', sessionDataOnly),
                store.commit('v2/game/code', null, { root: true }),
                store.commit('v2/game/mode', 'none', { root: true }),
                store.commit('v2/game/state', 'none', { root: true }),
                store.dispatch('clearCache'),
            ]);
        },
        saveCache(store) {
            toCache('game/spg', omit(store.state, ['math']));
        },
        loadCache(store) {
            const cache = fromCache('game/spg', null);

            if (!cache) return;

            store.commit('playlist', cache.playlist);

            store.commit('code', cache.code);

            store.commit('stats', cache.stats);

            if (cache.questions?.length) {
                const game = store.state.playlist.find(
                    (g) => g.code === cache.code,
                );

                if (game) {
                    const math = TopicsFactory.getTopicObject(game.gameType);

                    console.debug('store::game/spg::loadCache', cache);

                    store.commit('ready', { math, ...cache });

                    if (
                        store.getters.isStarted &&
                        !store.getters.idFinished &&
                        store.getters.isTimeGame &&
                        cache.timerSync
                    ) {
                        store.dispatch('start');
                    }
                }
            }
        },
        clearCache() {
            delCache('game/spg');
        },
        join: async (store, data) => {
            const playerName = store.rootGetters['v2/user/playerName'];

            console.debug('store::game/spg::join', data, playerName);

            store.commit('playlist', data.playlist || []);

            if (data.gameMode === 'self-paced-playlist') {
                store.commit('v2/game/state', 'spg/player/playlist', {
                    root: true,
                });

                return data.playlist.length > 0;
            }

            store.commit('code', data.gameCode);

            store.commit('v2/game/mode', CONSTANTS.SELF_PACED_GAME_MODE, {
                root: true,
            });

            return true;
        },
        play: async (store, code) => {
            const playerName = store.rootGetters['v2/user/playerName'];

            const uid = store.rootGetters['v2/user/uid'];

            const game = store.state.playlist.find((g) => g.code === code);

            if (!game) {
                // alert error message
                await store.dispatch(
                    'v2/ui/alert',
                    'Game not found! Refresh will be done.',
                    { root: true },
                );

                console.error('no game!', code);

                // refresh happens on 'home' pages
                // so false return will be used by
                // 'routing' component for home routing
                return false;
            }
            const player = game.players.find((p) =>
                uid ? p.userId === uid : !p.userId && p.name === playerName,
            );
            if (!player) {
                // alert error message
                console.error('no player!', game);

                await store.dispatch(
                    'v2/ui/alert',
                    'Something wrong, sorry. Refresh will be done.',
                    { root: true },
                );
                // refresh happens on 'home' pages
                // so false return will be used by
                // 'routing' component for home routing
                return false;
            }

            // saving code to play
            store.commit('code', code);

            store.commit('v2/game/mode', CONSTANTS.SELF_PACED_GAME_MODE, {
                root: true,
            });

            if (!player.sessionId) {
                const upd = await Api.post(`/self-paced-game/play/${code}`, {
                    data: { playerName },
                });

                console.debug('updatePlayer?', upd);

                store.commit('updatePlayer', upd);
            }

            store.commit('sessionId', player.sessionId);

            console.debug('store::game/spg::play', code, game, player);

            const math = TopicsFactory.getTopicObject(game.gameType);

            const timeAmount = roundUpToNearest10(
                ((game.options?.duration || 1) * 60) /
                    CONSTANTS.SPG_GAME_SECONDS_PER_TASK,
            );

            const qc = game.options?.questionCount || timeAmount;

            const questions = math.generateQuestions(qc);

            if (!store.rootState.v2.game.code) {
                store.commit('v2/game/code', code || game.joinGameCode, {
                    root: true,
                });
            }

            store.commit('ready', { math, questions });

            const nstate =
                game.gameVariant === 'time'
                    ? 'spg/player/countdown'
                    : 'spg/player/play';

            store.commit('v2/game/state', nstate, { root: true });

            store.dispatch('v2/game/saveCache', null, { root: true });

            console.debug('store::game/spg::play next state', nstate);

            return true;
        },
        answer: async (store, stats) => {
            if (store.rootState.v2.game.state === 'spg/player/finish') return;

            console.debug('store::game/spg::answer', stats);

            store.commit('answer', stats);

            if (
                store.getters.isCountGame &&
                store.stats &&
                Array.isArray(store.stats.answers) &&
                store.stats.answers.length >= store.questions.length
            ) {
                console.log('GOGOGOG');

                await store.dispatch('finish');
            }

            store.dispatch('saveCache');
        },
        loadHomework: async (store, { sseForce } = {}) => {
            if (
                store.state.playListCommitedAt &&
                moment().diff(
                    moment(store.state.playListCommitedAt),
                    'seconds',
                ) < 600 &&
                !sseForce
            )
                return store.state.playlist;

            const playlist = await Api.get('/home-assignments/my?pageSize=100');

            store.commit('playlist', playlist);
        },
        start: (store) => {
            console.debug('store::game/spg::start');

            const started = moment().format();

            const handler = setInterval(() => {
                const now = moment();

                store.commit('timerSync', now.format());

                const end = moment(store.getters.timeGameEndTime);

                if (now.unix() > end.unix()) {
                    // game finished
                    store.dispatch('finish');
                }
            }, 1000);

            store.commit('start', { started, handler });

            store.dispatch('saveCache');

            // store.commit('v2/game/state', 'spg/player/play', { root: true });
        },
        finish: async (store) => {
            console.debug(
                'store::game/spg::finish?',
                store.rootState.v2.game.state,
            );
            if (
                store.rootState.v2.game.state === 'spg/player/finish' ||
                store.rootState.v2.game.state === 'spg/player/finishing'
            ) {
                return;
            }

            console.debug('store::game/spg::finishing');

            store.commit('v2/game/state', 'spg/player/finishing', {
                root: true,
            });

            store.dispatch('saveCache');

            const started = moment(store.state.stats.started);
            const now = moment();
            const playedTime = now.unix() - started.unix();

            const game = await Api.post(
                `/self-paced-game/finish/${store.state.code}`,
                {
                    data: {
                        stats: { ...store.state.stats, playedTime },
                        sessionId: store.getters.session.sessionId,
                    },
                },
            );

            console.debug('store::game/spg::finish updated game', game);

            store.commit('finish', game);
            store.commit('v2/game/state', 'spg/player/finish', { root: true });

            console.debug('store::game/spg::finish');

            store.dispatch('saveCache');
        },
        deleteAssignment: async (store, report) => {
            const response = await Api.delete('/home-assignments', {
                data: {
                    spgId: report._id,
                    studentsIds: report?.players?.map(
                        (player) => player.userId,
                    ),
                },
            });

            if (response) {
                report.isLinkedAssignmentDeleted =
                    response.isLinkedAssignmentDeleted;
                store.commit('v2/teacher/updateReport', report, { root: true });
            }
        },
    },
};
