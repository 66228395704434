import { mapGetters } from 'vuex';

export const AppSSE = {
    computed: {
        ...mapGetters(['user']),
        channel: () => {
            return 'app';
        },
    },
    watch: {
        user(newVal, oldVal) {
            if (
                !oldVal &&
                !this.$store?.state?.v2?.sse?.channels?.[this.channel]
                    ?.evtSource &&
                newVal?.role !== 'teacher'
            )
                this.$store.dispatch('v2/sse/init', {
                    userId: this.user?.userId,
                    channel: this.channel,
                    eventHandler: this.eventHandler,
                });
            else if (!newVal)
                this.$store.dispatch('v2/sse/closeConnection', this.channel);
        },
    },
    beforeUnmount() {
        this.$store.dispatch('v2/sse/closeConnection', this.channel);
    },
    methods: {
        async eventHandler(data) {
            const handler = {
                ['runnerInvite']: () =>
                    this.$store.dispatch('v2/mathRunner/addInvite', data),

                ['runnerDeclinedInvite']: () =>
                    this.$store.dispatch(
                        'v2/mathRunner/updateSentInvites',
                        data,
                    ),

                ['runnerStarted']: () =>
                    this.$store.dispatch('v2/mathRunner/updateInvites', data),

                ['userUpdate']: () =>
                    this.$store.dispatch('v2/user/update', data.update),

                ['classUpdate']: () =>
                    this.$store.commit('v2/user/userClass', {
                        ...this.$store.getters['v2/user/classes'][
                            data.update.classInfo.classCode
                        ],
                        ...data.update.classInfo,
                    }),

                ['friendsUpdate']: () =>
                    this.$store.commit(
                        'v2/student/setFriendsData',
                        data.update,
                    ),

                ['commitedStars']: () =>
                    this.$store.dispatch(
                        'v2/homegame/commitStarsEventHandler',
                        data,
                    ),

                ['startBuild']: () =>
                    this.$store.dispatch('v2/homegame/updateClassBuildings', {
                        data: data.update?.buildingInfo,
                    }),

                ['updatedStudentsBuilding']: () =>
                    this.$store.dispatch('v2/homegame/updateStudentBuildings', {
                        data: data.update?.buildingInfo,
                    }),

                ['newClassmate']: () =>
                    this.$store.commit(
                        'v2/homegame/setClassBuildingsConfig',
                        data.update?.buildingsConfig,
                    ),

                ['newAssignment']: () =>
                    this.$store.dispatch('v2/game/spg/loadHomework', {
                        sseForce: true,
                    }),
                ['deletedAssignment']: () =>
                    this.$store.commit(
                        'v2/game/spg/removeFromPlaylist',
                        data?.deleted?.spgId,
                    ),

                ['heartbeat']: () => null,

                default: () => null,
            };

            await (handler[data.type] || handler.default)();
        },
    },
};
